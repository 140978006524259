export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  showShippingMethodCashierExpressButton: 'specs.stores.ShowShippingMethodInApplePayModal',
  EcomPlatformCartAndCheckout: 'specs.stores.EcomPlatformCartAndCheckout',
  AddPickupPointsToCart: 'specs.stores.AddPickupPointsToCart',
  SecureCheckoutVelo: 'specs.stores.ShowHideSecureCheckoutVelo',
  PickupPointsBmPhase1: 'specs.stores.PickupPointsBmPhase1',
  CartOOIThunderboltCSSOptimization: 'specs.stores.CartOOIThunderboltCSSOptimization',
  HideDeliveryMethodPickerWithTimeSlots: 'specs.stores.HideDeliveryMethodPickerWithTimeSlots',
  SendSelectCountryBI: 'specs.stores.SendSelectCountryBI',
  UseClientWidthToResizeCartWidget: 'specs.stores.UseClientWidthToResizeCartWidget',
  fixEmailNotifierInCart: 'specs.stores.fixEmailNotifierInCart',
} as const;
